import React from "react";
import { Switch, Route } from "react-router-dom";

import axios from "axios";

// import the library

const ClientOAuth2 = require("client-oauth2");

const yahooAuth = new ClientOAuth2({
  clientId:
    "dj0yJmk9Q0pNVTJBa0VZSzlEJmQ9WVdrOVowOUhOR1p5YmtzbWNHbzlNQT09JnM9Y29uc3VtZXJzZWNyZXQmc3Y9MCZ4PWQ1",
  //clientSecret: "c60672f04d671fef937c342a48ca0d36e6251d87",
  accessTokenUri: "https://api.login.yahoo.com/oauth2/get_token",
  authorizationUri: "https://api.login.yahoo.com/oauth2/request_auth",
  redirectUri: "https://localhost:3000/auth/yahoo/callback",
  scopes: ["fspt-r"],
});

const App = () => {
  return (
    <Switch>
      <Route exact path="/">
        <div>We've made it this far.</div>
      </Route>
      <Route
        exact
        path="/auth/login"
        render={() => {
          document.location = yahooAuth.code.getUri();
        }}
      />
      <Route
        exact
        path="/auth/yahoo/callback"
        render={() => {
          /*
          yahooAuth.code.getToken(document.location).then((user) => {
            console.log("TOKEN ACQUIRED!");
            console.log(user); //=> { accessToken: '...', tokenType: 'bearer', ... }
          });
          */
          axios
            .get("https://nifl.football/api/v1/auth/get_token")
            .then((response) => {
              console.log("TOKEN RESPONSE: ", response);
            })
            .catch((error) => {
              console.log("ERROR: ", error);
            });
        }}
      />
    </Switch>
  );
};

export default App;
